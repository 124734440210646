import React from 'react'
import styled from 'styled-components'
import Icon from '../../../svgs/play.svg'
import { centerize } from '../../tokens/positionings'
import { ButtonShadow } from '../../tokens/shadows'

const Component = () => {
  return (
    <Play>
      <Icon />
    </Play>
  )
}

const Play = styled.button`
  height: 72px;
  width: 72px;
  border-radius: 50%;
  background: white;
  ${centerize}
  ${ButtonShadow}
svg {
    margin-left: 4px;
  }
  :hover,
  :active {
    transform: scale(1.08);
  }
`
export default Component
